<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Feedy - Upravit <span class="text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <a-form :form="form" @submit="handleSubmit">
          <a-tabs @change="key => onTabChange(key, 'tabActiveKey')" :activeKey="tabActiveKey">
            <a-tab-pane key="main">
              <a-badge slot="tab">
                <a-icon type="info-circle"/>
                Hlavní údaje
              </a-badge>
              <a-form-item
                label="Název"
                :validate-status="error('name') ? 'error' : ''"
                :help="error('name') || ''"
              >
                <a-input placeholder="Název"
                         v-decorator="['name', {rules: [{max: 30, message: 'Název nemůže být delší než 30 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
              </a-form-item>

              <a-form-item
                label="URL"
                :validate-status="error('url') ? 'error' : ''"
                :help="error('url') || ''"
              >
                <a-input placeholder="URL"
                         v-decorator="['url', {rules: [{required: true, message: 'URL musí být vyplněn!'}]}]"/>
              </a-form-item>

              <a-form-item label="Zapnout?">
                <a-checkbox v-decorator="['is_active', { valuePropName: 'checked', initialValue: true }]"/>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="attributes">
              <a-badge slot="tab">
                <a-icon type="filter"/>
                Atributy
              </a-badge>
              <a-table
                :rowSelection="attributesRowSelection"
                :rowKey="record => record.id"
                :columns="item.feed_attributes.columns"
                :loading="item.feed_attributes.loading"
                :dataSource="detail.feed_attributes"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <template slot="customRender" slot-scope="text">{{ text }}</template>
                <template slot="tagRender" slot-scope="text">
                  <span v-if="text === 'UNDEFINED'">Není nastaveno</span>
                  <span v-else>&lt;{{ text }}/&gt;</span>
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="categories">
              <a-badge slot="tab">
                <a-icon type="unordered-list"/>
                Kategorie
              </a-badge>

              <a-tree
                :checkable="true"
                :checkedKeys="item.feed_categories.checkedKeys"
                @check="onCategoryCheck"
                :treeData="item.feed_categories.treeData"
                :checkStrictly="true"
              >
              </a-tree>
              <a-empty v-if="item.feed_categories.treeData.length === 0" >
                <a-spin v-if="item.feed_categories.loading" />
              </a-empty>

              <a-row v-else type="flex" justify="start" :gutter="30">
                <a-col :xl="6" :md="8" :sm="12" :xs="24" v-for="fc in item.feed_categories.items" :key="'fc'+fc.category_id">
                  <a-card style="border-radius: 4px;">
                    <div class="utils__title">#{{ fc.category_id }}</div>
                    <a-form-item
                      label="Název kategorie ve feedu"
                      :validate-status="error('feed_category_name['+fc.category_id+']') ? 'error' : ''"
                      :help="error('feed_category_name['+fc.category_id+']') || ''"
                    >
                      <a-input placeholder="Název kategorie ve feedu"
                               v-decorator="['feed_category_name['+fc.category_id+']', {rules: [{required: true, message: 'Název kategorie ve feedu musí být vyplněn!'}]}]"/>
                    </a-form-item>
                    <a-form-item
                      label="Kategorie obrázků"
                      :validate-status="error('image_category_id['+fc.category_id+']') ? 'error' : ''"
                      :help="error('image_category_id['+fc.category_id+']') || ''"
                    >
                      <a-select
                        :allowClear="true"
                        placeholder="-- Vyber kategorii obrázků --"
                        :options="imageCategories.map(x => { return { value: x.icy_id, title: x.icy_name, label: x.icy_name, key: x.icy_id } })"
                        v-decorator="['image_category_id['+fc.category_id+']']"
                        @change="changeCategoryId($event, fc.category_id)"
                      ></a-select>
                    </a-form-item>
                  </a-card>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
          <div class="form-actions">
            <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">
              Upravit
            </a-button>
          </div>
        </a-form>
      </div>
    </div>
  </section>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'

export default {
  components: {
    ActionTools,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      visitedTabs: new Set(),
      tabActiveKey: this.initTabActiveKey(),
      tabActiveKeys: [this.initTabActiveKey()],
      id: this.$route.params.id,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/feeds',
          title: 'Seznam',
        },
      ],
      item: {},
    }
  },
  computed: {
    attributesRowSelection() {
      const { selectedRowKeys } = this.item.feed_attributes
      return {
        selectedRowKeys,
        onChange: this.onAttributeSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.item.feed_attributes.selectedRowKeys = this.item.feed_attributes.selectedRowKeys.length === 0 ? this.attributes.map(item => item.id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
    imageCategories: function () {
      return this.$store.getters['imageCategory/filteredItems']('')
    },
    categories: function () {
      return this.$store.getters['category/currentLanguage']
    },
    detail: function () {
      return this.$store.getters['feeds/getDetail']
    },
  },
  methods: {
    changeCategoryId(imageCategoryId, categoryId) {
      this.item.feed_categories.items.some(x => {
        if (x.category_id === categoryId) {
          x.image_category_id = imageCategoryId === undefined ? null : imageCategoryId
          return true
        }
      })
    },
    onCategoryCheck(checkedKeys) {
      this.item.feed_categories.checkedKeys = checkedKeys
      const newItems = []
      checkedKeys.checked.forEach(x => {
        const categoryId = Number.parseInt(x.slice(3))
        let found = false
        this.item.feed_categories.items.some(y => {
          if (categoryId === y.category_id) {
            newItems.push(y)
            found = true
            return true
          }
        })
        if (!found) {
          newItems.push({
            category_id: categoryId,
            feed_id: this.id,
            feed_category_name: null,
            image_category_id: null,
          })
        }
      })
      this.item.feed_categories.items = newItems
    },
    onAttributeSelectChange(selectedRowKeys) {
      this.item.feed_attributes.selectedRowKeys = selectedRowKeys
    },
    initTabActiveKey() {
      return 'main'
    },
    onTabChange(key, type) {
      this[type] = key
    },
    initDetail() {
      this.loading = true
      this.$store.dispatch('feeds/getOne', this.id)
        .then(() => {
          if (this.tabActiveKeys.includes('main')) {
            this.initMain()
          }
          this.detail.feed_attributes.forEach(x => {
            if (x.sync && !this.item.feed_attributes.selectedRowKeys.includes(x.attribute_id)) {
              this.item.feed_attributes.selectedRowKeys.push(x.attribute_id)
            }
          })

          this.item.feed_categories.selectedKeys = []
          this.item.feed_categories.items = []
          this.detail.feed_categories.forEach(value => {
            if (!this.item.feed_categories.checkedKeys.checked.includes('cey' + value.category_id)) {
              this.item.feed_categories.checkedKeys.checked.push('cey' + value.category_id)
            }
            this.item.feed_categories.items.push({
              feed_id: this.id,
              category_id: value.category_id,
              feed_category_name: value.feed_category_name,
              image_category_id: value.image_category_id,
            })
            this.form.getFieldDecorator('feed_category_name[' + value.category_id + ']')
            this.form.getFieldDecorator('image_category_id[' + value.category_id + ']')
            this.form.setFieldsValue({
              ['feed_category_name[' + value.category_id + ']']: value.feed_category_name,
              ['image_category_id[' + value.category_id + ']']: value.image_category_id,
            })
          })
        }).catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    initMain() {
      this.form.getFieldDecorator('name')
      this.form.getFieldDecorator('url')
      this.form.getFieldDecorator('is_active')

      this.form.setFieldsValue({
        name: this.detail.name,
        url: this.detail.url,
        is_active: this.detail.is_active,
      })
    },
    loadCategories() {
      this.loading = true
      this.item.feed_categories.loading = true
      Promise.all([
        this.$store.dispatch('category/getList'),
        this.$store.dispatch('imageCategory/getList'),
      ])
        .then(() => {
          this.item.feed_categories.loaded = true
          this.item.feed_categories.items.forEach(value => {
            this.form.getFieldDecorator('feed_category_name[' + value.category_id + ']')
            this.form.getFieldDecorator('image_category_id[' + value.category_id + ']')
            this.form.setFieldsValue({
              ['feed_category_name[' + value.category_id + ']']: value.feed_category_name,
              ['image_category_id[' + value.category_id + ']']: value.image_category_id,
            })
          })
        }).catch(() => {
        })
        .finally(() => {
          this.loading = false
          this.item.feed_categories.loading = false
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('feeds/put', { id: this.id, item: this.getData(), from: 'DETAIL' })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    toTreeData(data) {
      return data.map(x => {
        return {
          key: 'cey' + x.cey_id,
          value: x.cey_id,
          title: '#' + x.cey_id + ' | ' + (x.languages.length === 0 ? 'není nastaveno' : x.languages[0].pivot.cle_name),
          children: this.toTreeData(x.all_children),
        }
      })
    },
    getData() {
      let returnObj = {
        name: this.form.getFieldValue('name'),
        url: this.form.getFieldValue('url'),
        is_active: this.form.getFieldValue('is_active'),
      }
      if (this.visitedTabs.has('attributes')) {
        returnObj = this.setFeedAttributes(returnObj)
      }
      if (this.item.feed_categories.loaded) {
        returnObj = this.setFeedCategories(returnObj)
      }
      return returnObj
    },
    setFeedAttributes(returnObj) {
      returnObj.feed_attributes = this.item.feed_attributes.selectedRowKeys
      return returnObj
    },
    setFeedCategories(returnObj) {
      const items = []
      this.item.feed_categories.items.forEach(x => {
        items.push({
          feed_id: Number.parseInt(this.id),
          category_id: x.category_id,
          feed_category_name: this.form.getFieldValue('feed_category_name[' + x.category_id + ']'),
          image_category_id: x.image_category_id,
        })
      })
      returnObj.feed_categories = items
      return returnObj
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
      this.item = {
        feed_attributes: {
          selectedRowKeys: [],
          columns: [
            {
              title: 'ID',
              dataIndex: 'id',
              sorter: (a, b) => a.id - b.id,
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Název',
              dataIndex: 'name',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => {
                return a.name.localeCompare(b.name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Tag',
              dataIndex: 'tag',
              sorter: (a, b) => {
                return a.tag.localeCompare(b.tag)
              },
              scopedSlots: {
                customRender: 'tagRender',
              },
            },
          ],
          loaded: false,
          loading: false,
        },
        feed_categories: {
          treeData: [],
          checkedKeys: {
            checked: [],
          },
          loaded: false,
          loading: false,
          items: [],
        },
      }
    },
  },
  created() {
    this.refreshComponent(this.id)
    this.initDetail()
    if (this.categories.length !== 0) {
      this.item.feed_categories.treeData = this.toTreeData(this.categories)
      this.form.validateFields()
    }
  },
  watch: {
    categories(newValue, oldValue) {
      if (newValue !== null && newValue.length > 0) {
        this.item.feed_categories.treeData = this.toTreeData(this.categories)
        this.form.validateFields()
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      this.initDetail()
    },
    tabActiveKey(newValue, oldValue) {
      this.visitedTabs.add(newValue)
      if (!this.tabActiveKeys.includes(newValue)) {
        if (newValue === 'main') {
          this.initMain()
        }
        this.tabActiveKeys.push(newValue)
      }
      if (newValue === 'categories') {
        if (!this.item.feed_categories.loaded) {
          this.loadCategories()
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "style.module";
</style>
